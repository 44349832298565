export default function parseYoutubeUrl(url) {
    let parser
    try {
        parser = new URL(url)
    } catch {
        return
    }

    let paramStr = ''
    let params = {}

    if (parser.search) {
        //?を除去
        paramStr = parser.search.substring(1)

        //urlパラメータをオブジェクトにまとめる
        paramStr.split('&').forEach(param => {
            const temp = param.split('=')
            //pramsオブジェクトにパラメータを追加
            params = {
                ...params,
                [temp[0]]: temp[1]
            }
        })
    } else {
        return undefined
    }
    return params.v
}