import firebase from 'firebase';
import 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyBXWlAwoX58Eu3jl-KQVq2SA2GBvndtU9s",
  authDomain: "pj-avc-prod.firebaseapp.com",
  databaseURL: "https://pj-avc-prod.firebaseio.com",
  projectId: "pj-avc-prod",
  storageBucket: "pj-avc-prod.appspot.com",
  messagingSenderId: "197956664929",
  appId: "1:197956664929:web:7a06b78bd2212dc8217420",
  measurementId: "G-BWFVVEWPG8"
};

// DEBUG ENV
// const firebaseConfig = {
//   apiKey: "AIzaSyD2ALD_5HEZyrKycihKAj2a04pSTyrn-Wg",
//   authDomain: "pj-avc-dev.firebaseapp.com",
//   databaseURL: "https://pj-avc-dev.firebaseio.com",
//   projectId: "pj-avc-dev",
//   storageBucket: "pj-avc-dev.appspot.com",
//   messagingSenderId: "513157103768",
//   appId: "1:513157103768:web:03c487ef9e6ba5b5ea90ed",
//   measurementId: "G-WLKGX91PTV"
// };

firebase.initializeApp(firebaseConfig);

export default firebase;
export const db = firebase.firestore();
export const auth = firebase.auth();