import React, { Component } from 'react'
import moment from 'moment'
import { auth, db } from './Firebase'

import 'flatpickr/dist/themes/material_green.css'
import Flatpickr from 'react-flatpickr'
import Modal from './components/Modal'
import Task from './components/Task'
import parseYoutubeUrl from './utils/parser'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      youTube_url: '',
      start_date: new Date(),
      end_date: new Date(),
      showModal: false,
      tasks: [],
      user_id: localStorage.getItem('uid') != null ? localStorage.getItem('uid') : ''
    }

    this.requestTask = this.requestTask.bind(this)
    this.getTaskList = this.getTaskList.bind(this)
    this.authentication = this.authentication.bind(this)
    this.deleteTask = this.deleteTask.bind(this)
    this.stopTask = this.stopTask.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.csvLink = React.createRef()
    
    console.log(this.state.user_id)
    if (this.state.user_id.length > 0) {
      this.getTaskList()
    } else {
      this.authentication()
    }
  }
  authentication() {
    const email = prompt('Please enter your email')
    const password = prompt('Please enter your password')
    auth.signInWithEmailAndPassword(email, password)
      .then(result => {
        this.setState({ user_id: result.user.uid})
        localStorage.setItem('uid', result.user.uid)
        this.getTaskList()
      })
      .catch(error => {
        var errorCode = error.code
        var errorMessage = error.message
        alert(errorMessage)
        window.location.reload()
      });
  }
  // すでに登録済みのタスクを取得
  getTaskList() {
    console.log("getTaskList")
    console.log("ここだよ")
    console.log(this.state.user_id)
    db.collection('users')
      .doc(this.state.user_id)
      .collection('tasks')
      .where('status', '>', 'DELETED')
      .get()
      .then(res => {
        let tasks = Object.assign([], this.state.tasks)
        res.forEach((doc, index) => {
          let data = doc.data()
          console.log("getTaskListのgetしたよ")

          data.task_id = doc.id
          data.start_date = moment(data.start_date.toDate())
          data.end_date = moment(data.end_date.toDate())
          data.user_id = this.state.user_id

          data.youtube_url = parseYoutubeUrl(data.youtube_url)
          tasks = tasks.concat(data)
          console.log(data)
          
        })
        tasks = tasks.sort((a, b) => b.end_date - a.end_date)
        this.setState({
          tasks: tasks
        })
      })
  }
  // タスクの追加
  requestTask() {
    let start_date = moment(this.state.start_date)
    let end_date = moment(this.state.end_date)

    // 正しく時刻が入力されている場合のみ処理
    if (!start_date.isValid() && !end_date.isValid()) {
      alert("入力した日付が正しくありません")
      return false
    }

    // はじめと終わりの時系列が問題ない場合のみ処理
    if (start_date >= end_date) {
      alert("入力した日付の順序が正しくありません")
      return false
    }

    if (this.state.youTube_url.length == 0 || parseYoutubeUrl(this.state.youTube_url) == undefined) {
      alert("正しいYoutubeのURLを入力してください")
      return false
    }

    let tasks = Object.assign([], this.state.tasks)

    const active_tasks = tasks.filter(item => item.status !== 'DONE')
    if (active_tasks.length > 1) {
      alert('現在のプランでは，動画を2つ以上同時に解析できません')
      return false
    }

    let new_task = {
      youtube_url: this.state.youTube_url,
      status: 'INIT',
      start_date: this.state.start_date,
      end_date: this.state.end_date
    }

    db.collection('users')
      .doc(this.state.user_id)
      .collection('tasks')
      .add(new_task)
      .then(res => {
        new_task.task_id = res.id
        new_task.title = res.title
        new_task.start_date = start_date
        new_task.end_date = end_date
        new_task.youtube_url = parseYoutubeUrl(new_task.youtube_url)
        new_task.user_id = this.state.user_id

        this.setState({
          tasks: tasks.concat(new_task)
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
  // タスクの削除
  deleteTask(task_id) {
    db.collection('users')
      .doc(this.state.user_id)
      .collection('tasks')
      .doc(task_id)
      .update({
        status: 'DELETED'
      })
      .then(() => {
        let tasks = Object.assign([], this.state.tasks)
        tasks = tasks.filter(item => item.task_id !== task_id)
        this.setState({ tasks: [], taskSelect: '' })
        this.setState({ tasks: tasks, taskSelect: '' })
      })
      .catch(function(error) {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }
  // タスクの強制停止
  stopTask(task_id) {
    db.collection('users')
      .doc(this.state.user_id)
      .collection('tasks')
      .doc(task_id)
      .update({
        status: 'DONE',
        end_date: this.state.end_date
      })
      .then(() => {
        let tasks = Object.assign([], this.state.tasks)
        for (var index in tasks) {
          if (tasks[index].task_id == task_id) {
            tasks[index].status = 'DONE'
          }
        }
        this.setState({ tasks: tasks })
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }
  handleChange(e, task_id = null) {
    console.log("handleChangeが呼ばれたよ")
    if (e.target.name === 'taskSelect') {
      if (e.target.value === '0') {
        this.stopTask(task_id)
      } else {
        this.deleteTask(task_id)
      }
    }
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  render() {
    return (
      <div>
        <input id="anPageName" name="page" type="hidden" value="index" />
        <div className="index">
          {this.state.showModal ? <Modal modalHandler={() => { this.setState({ showModal: false })}} />: ''}
          <div className="header">
            <div className="header-layout-container">
              <div className="logo">
                <img
                  anima-src="./img/index-img-logo@2x.png"
                  className="imglogo"
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                />
              </div>
              <div className="modal-button">
                <div className="modal-btn-border">
                  <img
                    anima-src="./img/index-icon@2x.png"
                    className="icon1"
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                  />
                  <div className="text" data-micromodal-trigger="modal" onClick={() => {
                    this.setState({
                      showModal: true
                    })
                  }}>
                    <a data-micromodal-trigger="modal-1">ご利用方法</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main" onClick={() => {
            this.setState({
              showModal: false
            })
          }}>
            <div className="main-top">
              <div className="top-layout-container">
                <div className="top-subtitle-layout-container">
                  <div className="top-subtitle">
                    AIが動画を解析して
                  <br />
                  人数をカウント
                </div>
                  <div className="top-description">
                    Youtubeの動画URLを入力すると、AIが動画を解析し人数をカウントします。
                  <br />
                  解析結果はCSV形式でエクスポートできます。
                  </div>
                </div>
                <div className="image-layout-container">
                  <img
                    anima-src="./img/index-image@2x.png"
                    className="top-image"
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                  />
                </div>
              </div>
            </div>
            <div className="main-center">
              <div className="center-layout-container">
                <input
                  className="texturl"
                  type="text"
                  placeholder="解析する動画のURLを入力してください"
                  name="youTube_url"
                  value={this.state.youTube_url}
                  onChange={this.handleChange}
                />

                <div className="date-picker-container">
                  <div className="date-picker-text">From</div>
                  <Flatpickr
                    data-enable-time
                    id="start_date"
                    name="start_date"
                    className="date-picker"
                    value={this.state.start_date}
                    parseDate={true}
                    onChange={e => {
                      this.handleChange({
                        target: { name: 'start_date', value: new Date(e) }
                      })
                    }}
                  />
                  <img
                    anima-src="./img/index-icon-1@2x.png"
                    className="date-picker-arrow-icon"
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                  />
                </div>
                <div className="date-picker-container">
                  <div className="date-picker-text">To</div>
                  <Flatpickr
                    data-enable-time
                    id="end_date"
                    name="end_date"
                    className="date-picker"
                    value={this.state.end_date}
                    onChange={e => {
                      this.handleChange({
                        target: { name: 'end_date', value: new Date(e) }
                      })
                    }}
                  />
                  <img
                    anima-src="./img/index-icon-1@2x.png"
                    className="date-picker-arrow-icon"
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                  />
                </div>
                <div className="analysis-button">
                  <div className="analysis-button-text" onClick={this.requestTask}>
                    解析する
                </div>
                </div>
              </div>
            </div>
            
            <div className="main-list">
              {this.state.tasks.map((task) =>
                <Task task={task} handleChange={this.handleChange}/>
              )}
            </div>
          </div>
          <div className="footer">
            <div className="footer-layout-container">
              <div className="footer-company-name">© 2020 Babel, inc.</div>
              <div className="footer-terms-of-service"><a href="https://www.notion.so/5ca577b77a654693aee49a1f6f92e724" target="_blank">利用規約</a></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default App
