import React, { Component } from 'react'
import moment from 'moment'
import { db, firestore } from '../Firebase'
import { ExportToCsv } from 'export-to-csv'
import parseYoutubeUrl from '../utils/parser'

class Task extends Component {
  constructor(props) {
    super(props)
    console.log(props.task)

    this.state = {
      task: props.task
    }

    this.updateTask(props.task.task_id)
  }

  summarize(list, unit) {
    let output = []
    let start_date = list[0].date.clone().startOf(unit)
    let end_date = list[list.length - 1].date.clone().startOf(unit).add(1, unit)
    const total_count = end_date.diff(start_date, unit)
    end_date = start_date.clone().add(1, unit)
    for (var i = 0; i < total_count; i++) {
      const filter_date = list.filter(item => item.date.isBetween(start_date, end_date))
      const total_count = filter_date.reduce((p, x) => p + x.count, 0)
      output.push({
        date: start_date.format('YYYY-MM-DD HH:mm:SS'),
        count: total_count
      })
      start_date.add(1, unit)
      end_date.add(1, unit)
    }
    return output
  }

  createCSV(data) {
    let output = []
    const min_data = this.summarize(data, 'minute')
    const hour_data = this.summarize(data, 'hour')
    const day_data = this.summarize(data, 'day')

    for (var i = 0; i < data.length; i++) {
      output.push({
        second_15_date: data[i].date.format('YYYY-MM-DD HH:mm:ss'),
        second_15_cout: data[i].count,
        minute_date: (min_data[i] !== undefined) ? min_data[i].date : "",
        minute_count: (min_data[i] !== undefined) ? min_data[i].count : "",
        hour_date: (hour_data[i] !== undefined) ? hour_data[i].date : "",
        hour_count: (hour_data[i] !== undefined) ? hour_data[i].count : "",
        day_date: (day_data[i] !== undefined) ? day_data[i].date : "",
        day_count: (day_data[i] !== undefined) ? day_data[i].count : ""
      })
    }
    return output
  }

  calcCount(task_id) {
    let sum = function (arr) {
      return arr.reduce(function (prev, current, i, arr) {
        return prev + current
      })
    }

    let average = function (arr, fn) {
      return sum(arr, fn) / arr.length
    }

    db.collection('users')
      .doc(this.state.task.user_id)
      .collection('tasks')
      .doc(task_id)
      .collection('results')
      .get()
      .then(res => {
        let output_data = []

        res.forEach((doc, index) => {
          let data = doc.data()

          output_data.push({
            date: moment.unix(doc.id),
            count: data.count
          })
          // console.log(moment.unix(doc.id))
        })

        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true
        }

        const csvExporter = new ExportToCsv(options)
        const csv_data = this.createCSV(output_data)
        csvExporter.generateCsv(csv_data)
      })
      .catch(error => {
        console.log(error)
      })
  }
  updateTask(task_id) {
    db.collection('users')
      .doc(this.state.task.user_id)
      .collection('tasks')
      .doc(task_id)
      .onSnapshot(docSnapshot => {
        let task = docSnapshot.data()
        task.task_id = docSnapshot.id
        task.start_date = moment(task.start_date.toDate())
        task.end_date = moment(task.end_date.toDate())
        task.youtube_url = parseYoutubeUrl(task.youtube_url)
        task.user_id = this.state.task.user_id
        this.setState({
          task: task
        })
      })
  }

  render() {
    return <div key={this.state.task.task_id} className="item-container">
      <div className="item-iframe-layout-container">
        <iframe src={'https://www.youtube.com/embed/' + this.state.task.youtube_url} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>

      <div className="item-right-layout-container">
        <div className="item-top-layout-container">
          <div className="item-top-left-layout-container">
            <div className="item-top-title-layout-container">
              {this.state.task.status == "DONE" ?
                <div className="item-top-status-done">
                  <img anima-src="./img/check@2x.png" class="item-top-status-icon" src="./img/check@2x.png"></img>
                    完了
                </div> : ''
              }
              {this.state.task.status == "PROCESS" ?
                <div className="item-top-status-process">
                  <img anima-src="./img/container-oval@2x.png" class="item-top-status-icon" src="./img/container-oval@2x.png"></img>
                    解析中
                </div> : ''
              }
              {this.state.task.status == "INIT" ?
                <div className="item-top-status-process">
                  <img anima-src="./img/container-oval@2x.png" class="item-top-status-icon" src="./img/container-oval@2x.png"></img>
                    待機中
                </div> : ''
              }
              <div className="item-top-title">{this.state.task.title}</div>
            </div>
            <div className="item-top-description-container">
              解析期間：{this.state.task.start_date.format('YYYY年MM月DD日 HH:mm')} - {this.state.task.end_date.format('YYYY年MM月DD日 HH:mm')}
            </div>
          </div>
          <div className="item-top-export-button" onClick={() => {
            console.log("csv download")
            this.calcCount(this.state.task.task_id)
          }}>
            <img anima-src="./img/container-shape-1@2x.png" class="export-button" src="./img/container-shape-1@2x.png"></img>

          </div>
          <div className="item-top-menu-button menu-button-dropdown">
            <img anima-src="./img/container-shape@2x.png" class="menu-button" src="./img/container-shape@2x.png"></img>

            <div class="menu-button-dropdown-content">
              <div className="menu-pause">
                <img anima-src="./img/pause_24px@2x.png" class="menu-pause-icon" src="./img/pause_24px@2x.png"></img>
                <a className="menu-pause-text" onClick={() => {
                  this.props.handleChange({ target: { name: 'taskSelect', value: '0' } }, this.state.task.task_id)
                }}>停止</a>
              </div>
              <div className="menu-delete">
                <img anima-src="./img/delete_24px@2x.png" class="menu-delete-icon" src="./img/delete_24px@2x.png"></img>
                <a className="menu-delete-text" onClick={() => {
                  this.props.handleChange({ target: { name: 'taskSelect', value: '1' } }, this.state.task.task_id)
                }}>削除</a>
              </div>
            </div>
          </div>
        </div>
        <div className="item-panels-layout-container">
          <div className="item-panel-container">
            <div className="item-panel-text">累計人数</div>
            <div className="item-panel-number">{this.state.task.total_count ? Math.round(this.state.task.total_count) : 0}</div>
          </div>
          <div className="item-panel-container">
            <div className="item-panel-text">平均人数(h)</div>
            <div className="item-panel-number">{this.state.task.average_count ? Math.round(this.state.task.average_count) : 0}</div>
          </div>
          <div className="item-panel-container">
            <div className="item-panel-text">最大人数(h)</div>
            <div className="item-panel-number">{this.state.task.max_count ? Math.round(this.state.task.max_count) : 0}</div>
          </div>
          <div className="item-panel-container">
            <div className="item-panel-text">最少人数(h)</div>
            <div className="item-panel-number">{this.state.task.min_count ? Math.round(this.state.task.min_count) : 0}</div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Task