import React, { Component } from 'react'

class Modal extends Component {
  render() {
    return <div className="modal">
      <div className="modal-row-layout-container">
        <div className="modal-title">ご利用方法</div>
        <div className="modal-step-layout-container">
          <div className="modal-step-container">
            <img
              anima-src="./img/img-howtouse1@2x.png"
              className="modal-step-image"
              src="./img/img-howtouse1@2x.png"
            />
            <div className="modal-step-title">入力</div>
            <div className="modal-step-description">解析したいYoutubeの動画URLを、入力欄に入力します。解析する時間を指定し、解析ボタンをクリックしてください。</div>
          </div>

          <div className="modal-step-container">
            <img
              anima-src="./img/img-howtouse2@2x.png"
              className="modal-step-image"
              src="./img/img-howtouse2@2x.png"
            />
            <div className="modal-step-title">解析</div>
            <div className="modal-step-description">AI（ディープランニング）が動画を解析し、人数をカウントします。ステータスが「完了」になるまでお待ちください。</div>
          </div>

          <div className="modal-step-container">
            <img
              anima-src="./img/img-howtouse3@2x.png"
              className="modal-step-image"
              src="./img/img-howtouse3@2x.png"
            />
            <div className="modal-step-title">出力</div>
            <div className="modal-step-description">動画内の人数データをCSV形式でエクスポートできます。</div>
          </div>
        </div>
        <div className="modal-close">
          <div className="modal-close-button" onClick={this.props.modalHandler}>
            閉じる
          </div>
        </div>
      </div>
    </div>
  }
}

export default Modal